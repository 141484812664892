import React, { forwardRef, useContext } from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Context
import SearchContext from '../../contexts/SearchContext'
import FormContext from '../../contexts/FormContext'

const TextInput = forwardRef(
  ({ className, error, groupId, schema, ...props }, ref) => {
    const { formFields, formDispatch } = useContext(FormContext)
    const errorInputClasses =
      error?.type === 'error'
        ? 'invalid border-error-dark bg-error-lighter'
        : error?.type === 'warning'
        ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
        : ''

    if (schema.type !== 'text' && schema.type !== 'number') return false
    const handleKeyDown = (e) => {
      const input = e.target
      const start = input.selectionStart
      const end = input.selectionEnd
      const value = input.value
      const searchCriteriaToSave = {}
      if (e.key === 'Delete' || e.key === 'Backspace') {
        //If the textbox is empty - do nothing
        if (!value) {
          return false
        }
        if (start !== end) {
          const updatedValue = value.slice(0, start) + value.slice(end)
          input.value = updatedValue
          input.setSelectionRange(start, start)
          if (input.name == 'q') {
            updatedValue === ''
              ? sessionStorage.removeItem('search')
              : sessionStorage.setItem('search', updatedValue)
          }
          searchCriteriaToSave[input.name] = updatedValue
          formDispatch({
            type: 'UPDATE_FORM_FIELD',
            id: groupId,
            value: searchCriteriaToSave,
          })
          e.preventDefault()
        } else {
          if (start < value.length) {
            //If the cursor is at the beginning and key pressed is Backspace - do nothing
            if (start == 0 && e.key === 'Backspace') {
              return false
            }
            const updatedValue = value.slice(0, start) + value.slice(start + 1)
            input.value = updatedValue
            input.setSelectionRange(start, start)
            if (input.name == 'q') {
              updatedValue === ''
                ? sessionStorage.removeItem('search')
                : sessionStorage.setItem('search', updatedValue)
            }
            searchCriteriaToSave[input.name] = updatedValue
            formDispatch({
              type: 'UPDATE_FORM_FIELD',
              id: groupId,
              value: searchCriteriaToSave,
            })
            e.preventDefault()
          } else {
            //If the cursor is at the end and key pressed is Delete - do nothing
            if (e.key === 'Delete') {
              return false
            }
            const updatedValue = value.slice(0, value.length - 1)
            input.value = updatedValue
            input.setSelectionRange(updatedValue.length, updatedValue.length)
            if (input.name == 'q') {
              updatedValue === ''
                ? sessionStorage.removeItem('search')
                : sessionStorage.setItem('search', updatedValue)
            }
            searchCriteriaToSave[input.name] = updatedValue
            formDispatch({
              type: 'UPDATE_FORM_FIELD',
              id: groupId,
              value: searchCriteriaToSave,
            })
            e.preventDefault()
          }
        }
      } else {
        if (input.name == 'q' && e.key.length == 1) {
          sessionStorage.setItem('search', value + e.key)
        }
      }
    }

    return (
      <input
        aria-invalid={error && error.type === 'error'}
        aria-required={schema.required}
        autoComplete={schema.autoComplete ? 'on' : 'off'}
        className={[
          'usa-input',
          className,
          errorInputClasses,
          //
          'bg-white',
          'border-base-dark',
          'border-1px',
          'display-inline-block',
          'line-height-sans-4',
          'padding-105',
          schema.examples ? 'margin-bottom-3' : '',
          'margin-top-0',
          'maxw-full',
          'radius-md',
          schema.icon
            ? [
                'nac-input-icon',
                'nac-icon--base',
                'nac-icon--bg-50',
                'nac-icon--left',
                `nac-icon--${schema.icon}`,
                'nac-icon--thin',
                'padding-left-4',
              ].join(' ')
            : '',
          'width-full',
        ].join(' ')}
        data-testid={`nac-input_${schema.id}`}
        id={`nac-text-input_${schema.id}`}
        maxLength={schema.maxLength}
        minLength={schema.minLength}
        name={schema.id}
        placeholder={schema.placeholder}
        ref={ref}
        required={schema.required}
        onKeyDown={handleKeyDown}
        type="text" //{schema.type}
        defaultValue={
          formFields?.[groupId] ? formFields[groupId][schema.id] : ''
        }
        {...props}
      />
    )
  }
)

TextInput.defaultProps = {}

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  /**
   * Id of a group of inputs for more granular identification
   */
  groupId: PropTypes.string,
  schema: inputSchemaPropTypes,
}

TextInput.displayName = 'TextInput'
export default TextInput
